// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Custom
@import 'custom';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import "~@fortawesome/fontawesome-free/css/all.css";
